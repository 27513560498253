import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Grid from '@material-ui/core/Grid'
import { makeStyles, createStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => createStyles({
  root: {
    width: '10px',
    [theme.breakpoints.up('sm')]: {
      width: '12px',
    },
    [theme.breakpoints.up('md')]: {
      width: '14px',
    },
    [theme.breakpoints.up('lg')]: {
      width: '16px',
    },
  },
}));

const PaymentPartner = () => {
  const classes = useStyles();
  const data = useStaticQuery(graphql`
    {
      allFile(filter: {extension: {regex: "/(png)/"}, relativeDirectory: {eq: "payment partner"}}) {
        edges {
          node {
            base
            childImageSharp {
              fluid (quality: 100, maxWidth: 72) {
                aspectRatio
                base64
                sizes
                src
                srcSet
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Grid container spacing={1} direction="row">
      {data.allFile.edges.map(({node}) => (
      <Grid item xs={3} className={classes.root}>
        <Img
          fluid={node.childImageSharp.fluid}/>  
      </Grid>
      ))
    }
    </Grid>
  )
}
export default PaymentPartner