import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Container, Box } from '@material-ui/core';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import CardTravelIcon from '@material-ui/icons/CardTravel';
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';
import Divider from '@material-ui/core/Divider';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const classes = useStyles();

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className={classes.panel}>
          {children}
        </Box>
      )}
    </div>
  );
}
  
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}
  
const useStyles = makeStyles((theme) => ({
  section:{
    marginTop: '-53px',
    padding: theme.spacing(0,3,3,3),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0,3,3,3),
    }
  },
  panel: {
    padding: theme.spacing(5),
  },
  tabsStyles: {
    backgroundColor:'#fff',
  },
  indicator: {
    backgroundColor: '#FFCB08',
  },
  tab: {
    lineHeight: 'inherit',
    minWidth: 0,

    [theme.breakpoints.up('md')]: {
      minWidth: 0,
    },
  },
  labelIcon: {
    minHeight: 53,
    '& $wrapper > *:first-child': {
      marginBottom: 0,
    },
  },
  textColorPrimary: {
    color: theme.palette.text.secondary,
    '&$selected': {
      color: '#FFCB08'
    },
    '&$disabled': {
      color: theme.palette.text.disabled
    },
  },
  selected: {},
  disabled: {},    
  wrapper: {
    flexDirection: 'row',
    letterSpacing: '1px',
    textTransform: 'uppercase',
    '& svg, .material-icons': {
      fontSize: 16,
      marginRight: 8,
    },
  },
}));
  
export default function FeaturePick() {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  const tabItemStyles = {
    root: classes.tab,
    labelIcon: classes.labelIcon, 
    textColorPrimary: classes.textColorPrimary, 
    selected: classes.selected, 
    disabled: classes.disabled, 
    wrapper: classes.wrapper};

  return (
    <Container maxWidth="lg" className={classes.section}>
      <Grid container xs={12} direction="row" justify="center" alignItems="center">
        <Grid item xs={12}>
          <Card style={{width:'100%'}}>
            <AppBar position="static" color="#fff">
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                aria-label="full width tabs example"
                classes={{indicator: classes.indicator}}
              >
                <Tab classes={tabItemStyles} label="Tour" icon={<CardTravelIcon />}{...a11yProps(0)} />
                <Tab classes={tabItemStyles} label="Transportation" icon={<EmojiTransportationIcon />} {...a11yProps(1)} />
              </Tabs>
            </AppBar>
            <SwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              <TabPanel value={value} index={0}>
                <Grid container spacing={3}style={{overflow: 'hidden'}}>
                  <Grid container xs={12} direction="column" justify="center" alignItems="center">
                    <Grid item xs={12} style={{width:'100%'}}>
                      <Typography variant="body1" style={{ fontWeight:'bold' }}>Main Tour</Typography>
                      <Divider />
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Button href={`${process.env.MODE}` === 'production' ? 'coming-soon/':'search/'} size="small" variant="outlined">
                      <Typography variant="caption" style={{ fontWeight:'normal', textTransform: 'none' }}>Open Tour</Typography>
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button href={`${process.env.MODE}` === 'production' ? 'coming-soon/':'search/'} size="small" variant="outlined">
                      <Typography variant="caption" style={{ fontWeight:'normal', textTransform: 'none' }}>Private Tour</Typography>
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button href={`${process.env.MODE}` === 'production' ? 'coming-soon/':'search/'} size="small" variant="outlined">
                      <Typography variant="caption" style={{ fontWeight:'normal', textTransform: 'none' }}>Cruise Tour</Typography>
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button href={`${process.env.MODE}` === 'production' ? 'coming-soon/':'search/'} size="small" variant="outlined">
                      <Typography variant="caption" style={{ fontWeight:'normal', textTransform: 'none' }}>Medical Tour</Typography>
                    </Button>
                  </Grid>
                  <Grid container xs={12} direction="column" justify="center" alignItems="center">
                    <Grid item xs={12} style={{width:'100%'}}>
                      <Typography variant="body1" style={{ fontWeight:'bold' }}>Religious</Typography>
                      <Divider />
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Button href={`${process.env.MODE}` === 'production' ? 'coming-soon/':'search/'} size="small" variant="outlined">
                      <Typography variant="caption" style={{ fontWeight:'normal', textTransform: 'none' }}>Ziarah</Typography>
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button href={`${process.env.MODE}` === 'production' ? 'coming-soon/':'search/'} size="small" variant="outlined">
                      <Typography variant="caption" style={{ fontWeight:'normal', textTransform: 'none' }}>Holy Land</Typography>
                    </Button>
                  </Grid>
                  <Grid container xs={12} direction="column" justify="center" alignItems="center">
                    <Grid item xs={12} style={{width:'100%'}}>
                      <Typography variant="body1" style={{ fontWeight:'bold' }}>Fun</Typography>
                      <Divider />
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Button href={`${process.env.MODE}` === 'production' ? 'coming-soon/':'search/'} size="small" variant="outlined">
                      <Typography variant="caption" style={{ fontWeight:'normal', textTransform: 'none' }}>Honeymoon</Typography>
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button href={`${process.env.MODE}` === 'production' ? 'coming-soon/':'search/'} size="small" variant="outlined">
                      <Typography variant="caption" style={{ fontWeight:'normal', textTransform: 'none' }}>Hobby Tour</Typography>
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button href={`${process.env.MODE}` === 'production' ? 'coming-soon/':'search/'} size="small" variant="outlined">
                      <Typography variant="caption" style={{ fontWeight:'normal', textTransform: 'none' }}>Eco Tour</Typography>
                    </Button>
                  </Grid>
                  <Grid container xs={12} direction="column" justify="center" alignItems="center">
                    <Grid item xs={12} style={{width:'100%'}}>
                      <Typography variant="body1" style={{ fontWeight:'bold' }}>Work &amp; Edu</Typography>
                      <Divider />
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Button href={`${process.env.MODE}` === 'production' ? 'coming-soon/':'search/'} size="small" variant="outlined">
                      <Typography variant="caption" style={{ fontWeight:'normal', textTransform: 'none' }}>Group Tour</Typography>
                    </Button>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Grid container spacing={3}>
                <Grid container xs={12} direction="column" justify="center" alignItems="center">
                    <Grid item xs={12} style={{width:'100%'}}>
                      <Typography variant="body1" style={{ fontWeight:'bold' }}>Ticket</Typography>
                      <Divider />
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Button href={`${process.env.MODE}` === 'production' ? 'coming-soon/':'search/'} size="small" variant="outlined">
                      <Typography variant="caption" style={{ fontWeight:'normal', textTransform: 'none' }}>Flight</Typography>
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button href={`${process.env.MODE}` === 'production' ? 'coming-soon/':'search/'} size="small" variant="outlined">
                      <Typography variant="caption" style={{ fontWeight:'normal', textTransform: 'none' }}>Train</Typography>
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button href={`${process.env.MODE}` === 'production' ? 'coming-soon/':'search/'} size="small" variant="outlined">
                      <Typography variant="caption" style={{ fontWeight:'normal', textTransform: 'none' }}>Bus</Typography>
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button href={`${process.env.MODE}` === 'production' ? 'coming-soon/':'search/'} size="small" variant="outlined">
                      <Typography variant="caption" style={{ fontWeight:'normal', textTransform: 'none' }}>Shuttle</Typography>
                    </Button>
                  </Grid>
                  <Grid container xs={12} direction="column" justify="center" alignItems="center">
                    <Grid item xs={12} style={{width:'100%'}}>
                      <Typography variant="body1" style={{ fontWeight:'bold' }}>Rent</Typography>
                      <Divider />
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Button href={`${process.env.MODE}` === 'production' ? 'coming-soon/':'search/'} size="small" variant="outlined">
                      <Typography variant="caption" style={{ fontWeight:'normal', textTransform: 'none' }}>Rent Car</Typography>
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button href={`${process.env.MODE}` === 'production' ? 'coming-soon/':'search/'} size="small" variant="outlined">
                      <Typography variant="caption" style={{ fontWeight:'normal', textTransform: 'none' }}>Rent Bus</Typography>
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button href={`${process.env.MODE}` === 'production' ? 'coming-soon/':'search/'} size="small" variant="outlined">
                      <Typography variant="caption" style={{ fontWeight:'normal', textTransform: 'none' }}>Rent Boat</Typography>
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button href={`${process.env.MODE}` === 'production' ? 'coming-soon/':'search/'} size="small" variant="outlined">
                      <Typography variant="caption" style={{ fontWeight:'normal', textTransform: 'none' }}>Rent Motorcycle</Typography>
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button href={`${process.env.MODE}` === 'production' ? 'coming-soon/':'search/'} size="small" variant="outlined">
                      <Typography variant="caption" style={{ fontWeight:'normal', textTransform: 'none' }}>Charter Flight</Typography>
                    </Button>
                  </Grid>
                </Grid>
              </TabPanel>
            </SwipeableViews>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}