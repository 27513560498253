import PropTypes from 'prop-types'
import React from 'react'

import { Link } from 'gatsby'
import withWidth from '@material-ui/core/withWidth';
import { makeStyles, createStyles } from '@material-ui/core/styles'
import {Grid, Container, Typography} from '@material-ui/core/'

import PaymentPartner from '../components/paymentPartner'
import AppStore from "../assets/img/badge/app-store.svg"
import PlayStore from "../assets/img/badge/play-store.png"

const useStyles = makeStyles(theme => createStyles({
  divider: {
    backgroundColor: '#FFD300',
    height: '24px',
    [theme.breakpoints.up('md')]: {
      height: '32px',
    },
    [theme.breakpoints.up('lg')]: {
      height: '40px',
    },
  },
  footer: {
    margin: '0px 0px 0px 0px',
    padding: '0px 0px 0px 0px',
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      margin: '16px 0px 16px 0px',
    },
    [theme.breakpoints.up('lg')]: {
      margin: '32px 0px 32px 0px',
    },
  },
}));

function Footer ( props ) {
  const classes = useStyles();
  const { width } = props;

  return (
    <Grid container justify='center'>
      <Grid item xs={12} className={classes.divider} />
      <Container disableGutters maxWidth="xl">
        <Grid container xs={12} spacing={(width === 'lg') || (width === 'xl') ? 0 : 7} justify='center' className={classes.footer}>
          <Grid item xs={"auto"}>
            <Typography gutterBottom variant="h6" style={{marginBottom:'16px'}}>
              Payment Partner
            </Typography>
            <PaymentPartner />
          </Grid>
          <Grid item xs={"auto"} lg={2}>
            <Typography gutterBottom variant="h6">
              About Us
            </Typography>
            <Typography>
              <Link to='coming-soon/' id='caption'>
                How to Order
              </Link>
            </Typography>
            <Typography>
              <Link to='coming-soon/' id='caption'>
                Contact Us
              </Link>
            </Typography>
            <Typography>
              <Link to='coming-soon/' id='caption'>
                Help Center
              </Link>
            </Typography>
            <Typography>
              <Link to='coming-soon/' id='caption'>
                Career
              </Link>
            </Typography>
            <Typography>
              <Link to='coming-soon/' id='caption'>
                About Us
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={"auto"} lg={2}>
            <Typography gutterBottom variant="h6">
              Product
            </Typography>
            <Typography>
              <Link to='coming-soon/' id='caption'>
                Tour Packages Marketplace
              </Link>
            </Typography>
            <Typography>
              <Link to='coming-soon/' id='caption'>
                Flight Ticketing
              </Link>
            </Typography>
            <Typography>
              <Link to='coming-soon/' id='caption'>
                Train Ticketing
              </Link>
            </Typography>
            <Typography>
              <Link to='coming-soon/' id='caption'>
                Hotel Ticketing
              </Link>
            </Typography>
            <Typography>
              <Link to='coming-soon/' id='caption'>
                Rent Transportation
              </Link>
            </Typography>
            <Typography>
            <Link to='coming-soon/' id='caption'>
                Airport Shuttle
              </Link>
            </Typography>
            <Typography>
              <Link to='coming-soon/' id='caption'>
                Bus Ticket
              </Link>
            </Typography>
            <Typography>
            <Link to='coming-soon/' id='caption'>
                Event Pass
              </Link>
            </Typography>
            <Typography>
              <Link to='coming-soon/' id='caption'>
                Restaurant Dine In
              </Link>
            </Typography>
            <Typography>
              <Link to='coming-soon/' id='caption'>
                My Experience
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={"auto"} lg={2}>
            <Typography gutterBottom variant="h6">
              Others
            </Typography>
            <Typography>
              <Link to='coming-soon/' id='caption'>
                Newsletter
              </Link>
            </Typography>
            <Typography>
              <Link to='coming-soon/' id='caption'>
                Privacy Policy
              </Link>
            </Typography>
            <Typography>
              <Link to='coming-soon/' id='caption'>
                Terms and Conditions
              </Link>
            </Typography>
            <Typography>
              <Link to='coming-soon/' id='caption'>
                Become Our Partner
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={"auto"} lg={2}>
            <Typography gutterBottom variant="h6">
              Download Our Apps
            </Typography>
            <Grid container direction='column' alignItems='center'>
              <Grid item style={{ width:135, height:50 }}>
                <Link to="coming-soon/"><img alt='Get it on Play Store' src={PlayStore}/></Link>
              </Grid>
              <Grid item style={{ width:120, height:40 }}>
                <Link to="coming-soon/"><img alt='Get it on App Store' src={AppStore}/></Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ textAlign:'center', marginBottom:10 }}>
          <Typography variant="caption" style={{fontWeight:'lighter' }}>
            Copyright © {new Date().getFullYear()} My Trip My Style. All Rights Reserved
          </Typography>
        </Grid>
      </Container>
    </Grid>
  )
}

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

Footer.propTypes = {
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
};

export default withWidth()(Footer);