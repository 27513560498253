/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import { Location } from '@reach/router';

import { createMuiTheme, ThemeProvider, responsiveFontSizes  } from '@material-ui/core/styles'

import Header from './header'
import HeaderHome from './headerHome'
import HeaderFull from './headerFull';

import Footer from './footer'

let theme = createMuiTheme({
  overrides: {
    MuiGridListTileBar: {
      titleWrap: {
        color: '#000',
      },
      title: {
        fontWeight: 'bolder',
        padding: '0px 0px 8px 0px'
      }
    },
    MuiTypography: {
      colorInherit: {
        color: '#fff'
      }
    }
  },
});
theme = responsiveFontSizes(theme);

export default function Layout (props) {
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <Location>
          {({ location }) => {
            return (
            <div style={{ minHeight: '100vh', backgroundColor: '#F6F6F6' }}>
              <ThemeProvider theme={theme}>
                {location.pathname === '/' ? (<HeaderHome siteTitle={data.site.siteMetadata.title} />):
                  location.pathname === '/coming-soon/' ? (<HeaderFull siteTitle={data.site.siteMetadata.title} />):
                  (<Header siteTitle={data.site.siteMetadata.title} />)}
              <div
                style={{
                  margin: `auto`,
                  maxWidth: 'auto',
                  // padding: `0px 1.0875rem 1.45rem`,
                  paddingTop: 0,
                }}
              >
                <main>{props.children}</main>
                <footer>
                  <Footer />
                </footer>
              </div>
              </ThemeProvider>
            </div>
            )
          }}
        </Location>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

Layout.propTypes = {
  location: PropTypes.object.isRequired,
}