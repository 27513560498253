import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'gatsby'

import { makeStyles, useTheme } from '@material-ui/core/styles'
import {Grid, Container, Typography} from '@material-ui/core/'

import { grey } from '@material-ui/core/colors';
import Logo from './logo'
import Background from "../assets/img/bg-coming.png"
import Coming from "../assets/img/coming-soon.png"

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';
import CssBaseline from '@material-ui/core/CssBaseline'

import MenuIcon from '@material-ui/icons/Menu';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import FiberNewIcon from '@material-ui/icons/FiberNew';

const drawerWidth = 240

const useStyles = makeStyles(theme => ({
  bgPrimary: {
    backgroundColor: "#eee",
  },
  root: {
    flexGrow: 1,
    marginBottom: '51px',
    [theme.breakpoints.up('sm')]: {
      marginBottom: '-59px',
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: '-69px',
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: '-86px',
    },
  },
  grow: {
    flexGrow: 1,
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: 'rgba(255, 255, 255, 1)',
    padding: theme.spacing(1,0,0,0),
    [theme.breakpoints.up('sm')]: {
      position: 'relative',
      background: 'rgba(0, 0, 0, 0)',
      padding: theme.spacing(2,1,1,1),
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  hide: {
    display: 'none',
  },
  section: {
    backgroundImage: "url(" + Background + ")",
    backgroundPosition: 'center center',
    backgroundSize:'cover',
    backgroundRepeat:'no-repeat',
    padding: theme.spacing(3,3,3,3),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(6,3,6,3),
    },
  },
  carousel: {
    minHeight: '40vh',
    [theme.breakpoints.up('sm')]: {
      minHeight: '60vh',
    },
    [theme.breakpoints.up('md')]: {
      minHeight: '80vh',
    },
    [theme.breakpoints.up('lg')]: {
      minHeight: '100vh',
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  sectionLogo: {
    display: 'block',
    margin: '0px 0px 0px 43%',
    minWidth:'64px', 
    maxWidth: '64px',
    [theme.breakpoints.up('sm')]: {
      margin: 'auto',
      minWidth:'80px', 
      maxWidth: '80px',
    },
    [theme.breakpoints.up('md')]: {
      minWidth:'96px', 
      maxWidth: '96px',
    },
    [theme.breakpoints.up('lg')]: {
      minWidth:'128px', 
      maxWidth: '128px',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionPad: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  image: {
    width:'270px',
    [theme.breakpoints.up('sm')]: {
      width:'430px',
    },
    [theme.breakpoints.up('md')]: {
      width:'600px',
    },
    [theme.breakpoints.up('lg')]: {
      width:'750px',
    },
  },
}))

export default function HeaderFull ( props ) {
  const classes = useStyles();
  const trigger = useScrollTrigger();
  const theme = useTheme();

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const mobileMenuId = 'primary-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
          size="small"
        >
          <ExitToAppIcon/>
        </IconButton>
        <Typography variant='body2' align='center'>
          Log In
        </Typography>
      </MenuItem>
      <MenuItem>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
          size="small"
        >
          <FiberNewIcon/>
        </IconButton>
        <Typography variant='body2' align='center'>
          Sign In
        </Typography>
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.bgPrimary}>
      <div className={classes.root}>
        <CssBaseline />
        <Slide appear={false} direction="down" in={!trigger}>
          <AppBar elevation={0} className={classes.appBar}>
            <Toolbar variant="dense">
              <div className={classes.sectionLogo} style={{ }}>
                <Logo />
              </div>
              <div className={classes.grow} />
              <div className={classes.sectionDesktop}>
                <Link to='coming-soon/'>
                  <Button size="small" style={{ color: grey[900], textTransform: "none", margin: theme.spacing(1) }}>
                    About
                  </Button>
                </Link>
                <Link to='coming-soon/'>
                  <Button size="small" style={{ color: grey[900], textTransform: "none", margin: theme.spacing(1) }}>
                    Destination
                  </Button>
                </Link>
                <Link to='coming-soon/'>
                  <Button size="small" style={{ color: grey[900], textTransform: "none", margin: theme.spacing(1) }}>
                    My Experience
                  </Button>
                </Link>
                <Link to='coming-soon/'>
                  <IconButton aria-label="show cart" size="small" style={{ color: grey[900], margin: theme.spacing(1) }}>
                    <Badge badgeContent={0} color="secondary">
                      <ShoppingCartIcon />
                    </Badge>
                  </IconButton>
                </Link>
                <Link to='coming-soon/'>
                  <Button size="small" variant="contained" style={{ backgroundColor: '#FFD300', color: '#fff', textTransform: "none", margin: theme.spacing(1) }}>
                    Login
                  </Button>
                </Link>
                <Link to='coming-soon/'>
                  <Button size="small" style={{ color: grey[900], textTransform: "none", margin: theme.spacing(1) }}>
                    Sign Up
                  </Button>
                </Link>
              </div>
              <div className={classes.sectionPad}>
                <Link to='coming-soon/'>
                  <Button size="small" style={{ color: grey[900], textTransform: "none", margin: theme.spacing(1) }}>
                    Destination
                  </Button>
                </Link>
                <Link to='coming-soon/'>
                  <IconButton aria-label="show 4 new mails" size="small" style={{ color: grey[900], margin: theme.spacing(1) }}>
                    <Badge badgeContent={4} color="secondary">
                      <ShoppingCartIcon />
                    </Badge>
                  </IconButton>
                </Link>
                <Link to='coming-soon/'>
                  <Button size="small" variant="contained" style={{ backgroundColor: '#FFD300', color: '#fff', textTransform: "none", margin: theme.spacing(1) }}>
                    Login
                  </Button>
                </Link>
                <Link to='coming-soon/'>
                  <Button size="small" style={{ color: grey[900], textTransform: "none", margin: theme.spacing(1) }}>
                    Sign Up
                  </Button>
                </Link>
              </div>
              <div className={classes.sectionMobile}>
                <IconButton
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                  style={{ color: grey[900] }}
                >
                  <MenuIcon />
                </IconButton>
              </div>
            </Toolbar>
          </AppBar>
        </Slide>
        {renderMobileMenu}
      </div>
      <Container maxWidth="false" className={classes.section}>
        <Grid container xs={12} className={classes.carousel} justify='center' alignItems="flex-end">
          <Grid item className={classes.image}>
            <img src={Coming} alt="Coming Soon" />
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

HeaderFull.propTypes = {
  siteTitle: PropTypes.string,
}

HeaderFull.defaultProps = {
  siteTitle: ``,
}